const initImgOpacity = () => {
  function changeImage() {
    // Находим все изображения внутри контейнера
    const images = document.querySelectorAll('.advertisement .advertisement__img-block');

    let currentIndex = 0;

    // Проходим по всем изображениям, чтобы найти текущее активное
    images.forEach((img, index) => {
      if (img.classList.contains('is-active')) {
        img.classList.remove('is-active');
        currentIndex = index;
      }
    });

    // Следующее изображение становится активным, если достигнут конец списка, начинаем с начала
    const nextIndex = (currentIndex + 1) % images.length;
    images[nextIndex].classList.add('is-active');
  }

  setInterval(changeImage, 8000);
};

export {initImgOpacity};
