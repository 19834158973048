const initLoader = () => {
  const loaderElement = document.querySelector('[data-loader]');

  if (!loaderElement) {
    return;
  }

  window.addEventListener('DOMContentLoaded', () => {
    window.addEventListener('load', () => {
      setTimeout(() => {
        loaderElement.classList.remove('is-active');
      }, 2000);
    });
  });
};

export {initLoader};
