/* eslint-disable no-unused-vars */
const initSlide = () => {
  const swiperSection = document.querySelector('.swiper-link');

  if (swiperSection) {
    // eslint-disable-next-line no-undef
    let swiper = new Swiper(swiperSection, {
      slidesPerView: 'auto',
      spaceBetween: 12,
      loop: false,
      speed: 500,
      slideToClickedSlide: true,
      touchStartForcePreventDefault: true,
      slideActiveClass: 'active-slide',
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        1024: {
          spaceBetween: 20,
        },
      },
    });

    let slides = document.querySelectorAll('[data-swiper-index]');

    let block = document.querySelector('.content-block');
    let blockAttribute = block.getAttribute('data-content-index');

    for (let i = 0; i < slides.length; i++) {
      let slideAttribute = slides[i].getAttribute('data-swiper-index');

      if (slideAttribute === blockAttribute) {
        slides[i].classList.add('active');
      }
    }

    slides.forEach((slide, index) => {
      if (slide.classList.contains('active')) {
        const activeIndex = Array.from(slide.parentElement.children).indexOf(slide);
        swiper.slideToLoop(activeIndex, 0);
      }
    });
  }
};


export {initSlide};
