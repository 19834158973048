/* eslint-disable no-unused-expressions */
const initModalIcon = () => {
  let modalBlock = document.querySelector('[data-modal]');

  if (!modalBlock) {
    return;
  }
  const imagesSrc = document.querySelectorAll('[data-src]');
  const images = Array.from(imagesSrc).map((el) => el.src);

  let index = 0;

  const imgElement = modalBlock.querySelector('[data-photo]');


  function change() {
    imgElement.src = images[index];

    index >= images.length - 1 ? index = 0 : index++;
  }

  window.onload = function () {
    setInterval(change, 5000);
  };
};

export {initModalIcon};
